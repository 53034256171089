import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Loading from "./components/Loading";
import { useAuth, UserProvider } from "./contexts/UserContext";
import { canAccessDashboard } from "./models/User";
import isMobile from "./utils/isMobile";

const Home = lazy(() => import("./pages/home"));
const Profiler = lazy(() => import("./pages/profiler"));
const Dashboard = lazy(() => import("./pages/dashboard"));
const Signin = lazy(() => import("./pages/sign-in"));
const Comparisons = lazy(() => import("./pages/comparisons"));
const ResetPassword = lazy(() => import("./pages/reset-password"));
const MobileWarning = lazy(() => import("./pages/mobile-warning"));
const NewPassword = lazy(() => import("./pages/new-password"));
const NewEmail = lazy(() => import("./pages/new-email"));
const Account = lazy(() => import("./pages/account"));
const SupportServices = lazy(() => import("./pages/support-services"));
const CheckAuth = lazy(() => import("./pages/check-auth"));
const Resource = lazy(() => import("./pages/resource"));
const CheckIns = lazy(() => import("./pages/check-ins"));

const PublicRoute = (props) => {
  const { userToken } = useAuth();

  if (userToken && ["/sign-in", "/sign-up"].includes(props.path)) {
    return <Redirect to="/" />;
  }

  if (isMobile() && props.path !== "/sign-up") {
    return <MobileWarning />;
  }

  return <Route {...props} />;
};

const ProtectedRoute = ({ redirectPath = "/sign-in", children, ...rest }) => {
  const { userToken } = useAuth();

  if (isMobile()) {
    return <MobileWarning />;
  }

  if (!userToken) {
    return <Redirect to={redirectPath} />;
  }

  return <Route {...rest}>{children}</Route>;
};

const DashboardRoute = ({ children, ...rest }) => {
  const { user, userToken } = useAuth();
  if (userToken && !user) {
    return <Loading isFullScreen />;
  }

  if (canAccessDashboard(user)) {
    return <ProtectedRoute {...rest}>{children}</ProtectedRoute>;
  }

  return <Redirect to="/" />;
};

const IndexRoute = ({ children, ...props }) => {
  const { userToken, user } = useAuth();

  if (!userToken) {
    return <Redirect to="/sign-in" />;
  }

  if (!user) {
    return <Loading isFullScreen />;
  }

  return <Route {...props}>{children}</Route>;
};

function App() {
  return (
    <Suspense fallback={<Loading isFullScreen />}>
      <Router>
        <UserProvider>
          <Switch>
            <IndexRoute path="/" exact>
              <Home />
            </IndexRoute>
            <DashboardRoute path="/dashboard">
              <Dashboard />
            </DashboardRoute>
            <DashboardRoute path="/profiler">
              <Profiler />
            </DashboardRoute>
            <DashboardRoute path="/check-ins">
              <CheckIns />
            </DashboardRoute>
            <DashboardRoute path="/comparisons">
              <Comparisons />
            </DashboardRoute>
            <PublicRoute path="/sign-in">
              <Signin />
            </PublicRoute>
            <PublicRoute path="/reset-password">
              <ResetPassword />
            </PublicRoute>
            <PublicRoute path="/new-password">
              <NewPassword />
            </PublicRoute>
            <ProtectedRoute path="/account">
              <Account isProtected />
            </ProtectedRoute>
            <ProtectedRoute path="/new-email">
              <NewEmail isProtected />
            </ProtectedRoute>
            <IndexRoute path="/support-services">
              <SupportServices isProtected />
            </IndexRoute>
            <PublicRoute path="/check-auth">
              <CheckAuth />
            </PublicRoute>
            <ProtectedRoute path="/resources">
              <Resource />
            </ProtectedRoute>
          </Switch>
        </UserProvider>
      </Router>
    </Suspense>
  );
}

export default App;
