export const canAccessDashboard = (user) => {
  const role = user?.data?.role
  const roleArray = [
    "ROLE_REPORT_GROUP_LEADER",
    "ROLE_REPORT_ADMIN",
    "ROLE_REPORT_SUPER_ADMIN",
    "ROLE_SYSTEM_ADMIN" 
  ]
  return roleArray.some((item) => role?.includes(item))
};

export const getOrganisationTag = (user) => user?.data?.organisationTag;

export const User = {
  canAccessDashboard,
  getOrganisationTag,
};

export default User;
